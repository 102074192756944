// REGLES DE STYLE GÉNÉRALES DU PROJET
@tailwind base;
@tailwind components;
@tailwind utilities;

// App.js

.main-content-container {
  background-color: $bg-color;
  min-height: 100vh;
}
