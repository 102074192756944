// Variables de couleurs primaires

$primary-50: #FFE5E9;
$primary-100: #FFCCD3;
$primary-200: #FF99A7;
$primary-300: #FF667A;
$primary-400: #FF334E;
$primary-500: #D6001D;
$primary-600: #CC001B;
$primary-700: #990014;
$primary-800: #66000E;
$primary-900: #330007;
$primary-950: #1A0003;

// Variables de couleurs secondaires

$secondary-50: #E7F1FE;
$secondary-100: #CFE2FC;
$secondary-200: #9FC6F9;
$secondary-300: #6FA9F6;
$secondary-400: #3F8DF3;
$secondary-500: #041E41;
$secondary-600: #0C5AC0;
$secondary-700: #094390;
$secondary-800: #062D60;
$secondary-900: #031630;
$secondary-950: #031630;

// Autres couleurs

$bg-color: #F2F4FB;
$p-color: #53565A;
$total-color: #2C5234;
