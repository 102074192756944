// Landing.js

.landing-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  padding: 2.4375rem /* 39/16 */
;
  border-radius: 1rem;
}

.landing-content-container {
  display: flex;
  flex-direction: column;
  //max-height: calc(100vh - 2.4375rem);
  gap: 1.5rem /* 24/16 */
;
  padding-block-end: 2.4375rem /* 39/16 */
;
}

.landing-logo-container {
  display: flex;
  justify-content: center;
    padding-block-end: 2rem;
}

.landing-txt-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;;
}

.primary-txt {
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.4rem /* 38.4/16 */
;
}

.secondary-txt {
  line-height: 1.6rem /* 25.6/16 */
;
  color: $p-color;
}

.landing-btn-container {
display: flex;
justify-content: flex-start;
}

.landing-img-container {
  display: flex;
  justify-content: center;
  //max-height: calc(100vh - 2.5rem);
}

.truck-img {
  border-radius: 1rem;
    width: 100%;
    object-fit: cover;
}

// Responsive design

@media (min-width: 64rem) {
  .landing-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0;
  };

  .landing-content-container {
    padding-inline: 3.3125rem  /* 53/16 */;
    padding-block-start: 2.4375rem  /* 39/16 */;
    gap: 0;
  }

  .landing-logo-container {
    justify-content: flex-start;
    padding-block-end: clamp(7.25rem, -10.596rem + 27.885vw, 14.5rem);
  }

  .landing-btn-container {
    display: block;
    width: fit-content;
    padding-block-start: 3rem /* 48/16 */
  ;
  }

  .landing-img-container {
  }
}

@media (min-width: 90rem) {
  .landing-content-container {
    max-height: calc(100vh - 2.4375rem);
  }

  .landing-img-container {
    max-height: calc(100vh - 2.5rem);
  }
}