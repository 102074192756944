// CardBtn.js

.add-card-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 240px);
  // min-height: 100vh;
  padding-block: 1.3125rem /* 21/16 */;
  padding-inline: 1.5rem /* 24/16 */;
  border-color: $secondary-500;
}

// MainBtn.js

.main-btn {
  background-color: $primary-500;
  padding-inline: 2rem;
  padding-block: 1rem;
  color: white;
}

.main-btn:hover {
  background-color: $primary-700;
}

.duplicate-btn-container{
  background-color: $primary-500;
  color: white;
  &:hover{
    background-color: $primary-700;
  }
}

// Responsive Design

@media (max-width: 23.625rem /* 378/16 */) {
  .main-btn {
    padding-inline: 1.5rem;
    padding-block: 0;
  }
}

@media (max-width: 48rem /* */) {
  .add-card-btn {
    min-height: auto;
    height: 100%;
  }
}
