// DÉCLARATION DES FONTS UTILISÉES DANS LE PROJET

@font-face {
  font-family: "scania_officebold";
  src: url("../assets/fonts/scaniaoffice-bold-webfont.woff2") format("woff2"),
  url("../assets/fonts/scaniaoffice-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

$ScaniaOfficeBold: "scania_officebold", sans-serif;

@font-face {
  font-family: "scania_office_condensedbold";
  src: url("../assets/fonts/scaniaofficecondensed-bold-webfont.woff2") format("woff2"),
  url("../assets/fonts/scaniaofficecondensed-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

$ScaniaOfficeCondensedBold: "scania_office_condensedbold", sans-serif;

@font-face {
  font-family: "scania_office_condenseditalic";
  src: url("../assets/fonts/scaniaofficecondensed-italic-webfont.woff2") format("woff2"),
  url("../assets/fonts/scaniaofficecondensed-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

$ScaniaOfficeCondensedItalic: "scania_office_condenseditalic", sans-serif;

@font-face {
  font-family: "scania_office_condensedRg";
  src: url("../assets/fonts/scaniaofficecondensed-regular-webfont.woff2") format("woff2"),
  url("../assets/fonts/scaniaofficecondensed-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

$ScaniaOfficeCondensedRg: "scania_office_condensedRg", sans-serif;

@font-face {
  font-family: "scania_officeitalic";
  src: url("../assets/fonts/scaniaoffice-italic-webfont.woff2") format("woff2"),
  url("../assets/fonts/scaniaoffice-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

$ScaniaOfficeItalic: "scania_officeitalic", sans-serif;

@font-face {
  font-family: "scania_officeregular";
  src: url("../assets/fonts/scaniaoffice-regular-webfont.woff2") format("woff2"),
  url("../assets/fonts/scaniaoffice-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

$ScaniaOfficeRegular: "scania_officeregular", sans-serif;

@font-face {
  font-family: "scania_sans_headlinebold";
  src: url("../assets/fonts/scaniasansheadline-bold-webfont.woff2") format("woff2"),
  url("../assets/fonts/scaniasansheadline-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

$ScaniaSansHeadlineBold: "scania_sans_headlinebold", sans-serif;

@font-face {
  font-family: "scania_sans_headlineregular";
  src: url("../assets/fonts/scaniasansheadline-regular-webfont.woff2") format("woff2"),
  url("../assets/fonts/scaniasansheadline-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

$ScaniaSansHeadlineRegular: "scania_sans_headlineregular", sans-serif;

h1 {
  font-size: 1.5rem /* 24/16 */
  !important;
  line-height: 2.125rem /* 34/16 */
  !important;
  font-family: $ScaniaSansHeadlineBold !important;
  color: var(--secondary-500) !important;
  // margin-bottom: 2.1875rem  /* 35/16 */ !important;
}

h2 {
  font-size: 1.25rem /* 20/16 */
  !important;
  line-height: 1.875rem /* 30/16 */
  !important;
  font-family: $ScaniaSansHeadlineBold !important;
  color: var(--secondary-500) !important;
  // margin-bottom: 1.875rem  /* 30/16 */ !important;
}

h3 {
  font-size: 1rem /* 16/16 */
  !important;
  line-height: 1.625rem /* 26/16 */
  !important;
  font-family: $ScaniaSansHeadlineBold !important;
  color: var(--secondary-500) !important;
  // margin-bottom: 1.5625rem  /* 25/16 */ !important;
}

p {
  font-size: 1rem /* 16/16 */
;
  line-height: normal;
  font-family: $ScaniaOfficeRegular;
  color: var(--secondary-500);
}

strong {
  font-size: 1rem /* 16/16 */
;
  line-height: normal;
  font-family: $ScaniaOfficeBold;
  color: var(--secondary-500);
}

span {
  font-size: 1rem /* 16/16 */
;
  line-height: normal;
  font-family: $ScaniaOfficeRegular;
  color: var(--secondary-500);
}

a {
  line-height: normal;
  font-family: $ScaniaOfficeRegular;
  color: var(--secondary-500);
  font-size: 0.875rem /* 14/16 */
;
  font-style: normal;
  font-weight: 400;
  text-decoration-line: underline;
  display: block;
}

.text-card {
  font-family: $ScaniaOfficeRegular;
  font-size: 0.875rem /* 14/16 */
;
  line-height: 1.25rem /* 20/16 */
;
  color: $secondary-500;
  font-weight: 700;
}

.value-card {
  font-family: $ScaniaOfficeRegular;
  font-size: 0.875rem /* 14/16 */
;
  line-height: 1.25rem /* 20/16 */
;
  color: $secondary-500;
  font-weight: 400;
}

.text-red {
  color: $primary-500;
}

.text-total {
  color: white;
  font-weight: 400;
}

.text-btn {
  font-family: $ScaniaOfficeBold;
  font-size: 1rem /* 16/16 */
;
  line-height: 1.1875rem /* 19/16 */
;
  font-weight: 700;
  text-transform: uppercase;
}

.ScaniaOfficeBold {
  font-family: $ScaniaOfficeBold;
}

.ScaniaOfficeCondensedBold {
  font-family: $ScaniaOfficeCondensedBold;
}

.ScaniaOfficeCondensedItalic {
  font-family: $ScaniaOfficeCondensedItalic;
}

.ScaniaOfficeCondensedRg {
  font-family: $ScaniaOfficeCondensedRg;
}

.ScaniaOfficeItalic {
  font-family: $ScaniaOfficeItalic;
}

.ScaniaOfficeRegular {
  font-family: $ScaniaOfficeRegular;
}

.ScaniaSansHeadlineBold {
  font-family: $ScaniaSansHeadlineBold;
}

.ScaniaSansHeadlineRegular {
  font-family: $ScaniaSansHeadlineRegular;
}