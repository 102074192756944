// CardData.js & CardDetails.js

.inputs-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem /* 24/16 */
;
  padding-block-start: 1.5rem /* 24/16 */
;
  padding-inline: clamp(0.625rem, -0.161rem + 1.637vw, 1.313rem);
}

// SelectInput.js

.select-input {
  width: clamp(9.375rem, -0.482rem + 20.536vw, 18rem);
}

// CardDat.js

.select-input-index-two {
}

// TextInput.js

.text-input-container {
  display: flex;
  width: clamp(9.375rem, -0.482rem + 20.536vw, 18rem);
  flex-direction: column;
  gap: 0.625rem /* 8/16 */
;
}

// RangeInput.js
/* Arrondir le bouton range */

.range.rounded-thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  border-radius: 50%;
  color: $secondary-500;
  /* Autres styles que vous voulez appliquer au curseur */
}

.range.rounded-thumb::-moz-range-thumb {
  border-radius: 50%;
  /* Autres styles que vous voulez appliquer au curseur */
}

// Responsive design

@media (max-width: 48rem) {
  .text-input-container {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding-inline-end: clamp(1.5rem, -2.643rem + 5.714vw, 4.5rem);
  }
;

  .select-input {
    width: 15.3125rem  /* 245/16 */;
  }
}

@media (min-width: 90rem) {
  .text-input-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-inline-end: clamp(2.5rem, -2.643rem + 5.714vw, 4.5rem);
    flex-direction: row;
    align-items: center;
  }
;

  .text-input {
    width: 40%
  }
;
}