// TCOCalculator.js

.cards-content-container {
  padding-inline: clamp(1.5rem, -2.143rem + 7.589vw, 4.688rem);
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $bg-color;
}

.options-container {
  display: flex;
}

.focused-option {
  text-transform: uppercase;
  background-color: $secondary-500;
  color: white;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  cursor: pointer;
}

.unfocused-option {
  text-transform: uppercase;
  cursor: pointer;
}

// TCOGetData.js

.cards-data-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: clamp(1rem, -0.143rem + 2.381vw, 2rem);
  margin-block-end: 3rem;
}

// TCOShowResult.js

.cards-details-container {
  display: grid;
  gap: clamp(1rem, -0.143rem + 2.381vw, 2rem);
  background-color: white;

  padding-inline: clamp(1.25rem, -0.25rem + 3.125vw, 2.563rem);
  padding-block: 1.5rem /* 24/16 */;
  border-radius: 0.5rem /* 8/16 */;
  margin-block-end: 3rem /* 48/16 */;
}

.cards-details-container-one {
  grid-template-columns: repeat(2
  , 1fr);
}

.cards-details-container-two {
  grid-template-columns: repeat(3, 1fr);
  gap: clamp(0.313rem, -1.616rem + 4.018vw, 2rem);
  padding-inline: clamp(0.75rem, -1.322rem + 4.317vw, 2.563rem);
}

.cards-graphic-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem /* 24/16 */;
  background-color: white;
  padding-inline: clamp(1.25rem, -0.25rem + 3.125vw, 2.563rem);
  padding-block: 2rem;
  border-radius: 0.5rem /* 8/16 */;
  margin-block-end: 3rem /* 48/16 */;
}

// CardData.js & CardDetails.js

.card {
  background-color: white;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding-block-start: 1.3125rem /* 21/16 */;
  padding-block-end: 2.5rem /* 40/16 */;
  border-radius: 1rem;
  box-shadow: 0 0 0.625rem /* 10/16 */ rgba(0, 0, 0, 0.15);
}

.empty-card {
  border: 2px solid $secondary-500;
  border-radius: 1rem;
}

.card-data-index-two {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  padding-block-end: 0;
}

.total-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-inline: 1rem;
  padding-block: 0.8125rem /* 13/16 */;
  background-color: $total-color;
  border-radius: 0.625rem /* 10/16 */;
}

// CardGraphic.js

.card-graphic {
  box-shadow: 0 0 0.625rem /* 10/16 */ rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
}

.chart {
  height: 100vh;
  width: 100%;
}

.chart svg {
  width: 100%;
  height: 100%;
}

.chart-title {
  position: absolute;
  z-index: 1;
  padding-block-start: 5rem /* 24/16 */;
  padding-inline-start: 10rem;
}

// Responsive design

@media (max-width: 48rem) {
  .cards-data-container {
    display: flex;
    flex-direction: column;
    margin-block-end: 3rem;
  }

  .cards-details-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem; /* 24/16 */
  }

  .chart-title {
    position: relative;
    padding-inline-start: 2rem;
  }
}

@media screen and (min-width: 48rem) {
  .cards-graphic-container {
    flex-direction: row;
  }

  .chart-title {
    position: relative;
    padding-inline: 2rem;
  }
}

@media screen and (min-width: 68rem) {
  .cards-details-container-one {
    //gap: clamp(2rem, -10.526rem + 18.421vw, 12.5rem);
    //padding-inline: clamp(3rem, -8.333rem + 16.667vw, 12.5rem);
  }
}

@media screen and (min-width: 90rem) {
  .card-graphic {
    //width: clamp(34.375rem, -21.875rem + 62.5vw, 56.25rem) /* 550/16 */;
  }

  .chart-title {
    padding-inline-start: clamp(5rem, -3.372rem + 9.302vw, 10rem);
  }
}
