// Navbar.js

.navbar-container {
  padding-inline: clamp(1.5rem, -2.143rem + 7.589vw, 4.688rem);
  background-color: white;
  margin-block-end: 2.125rem  /* 34/16 */;
}

.calculator-link {
  text-transform: uppercase;
  border-left: 0.0625rem  /* 1/16 */ solid lightgray;
  padding-inline-start: 0.8125rem  /* 13/16 */;
}